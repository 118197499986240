/**
 * Add your config changes here.
 * @module config
 * @example
 * export default function applyConfig(config) {
 *   config.settings = {
 *     ...config.settings,
 *     port: 4300,
 *     listBlockTypes: {
 *       ...config.settings.listBlockTypes,
 *       'my-list-item',
 *    }
 * }
 */
import installAdvancedLink from './addons/AdvancedLink';
import { setupServerMiddleware } from './middleware';


import {
  TypesView,
  SearchSchemaEnhancer,
  ListingTableVariation,
  ListingNewsVariation,
  DoeDefinition
} from "@directives/components";


import { CookieConsent } from '@directives/components';

import '@plone/volto/config';

// import ReactGA from "react-ga4";
import GoogleAnalytics from './googleAnalytics';

const portals = {
  "domains": [
    {
      "site": "directives",
      "dev_domain": "https://directives.dev.doxcelerate.com",
      "prod_domain": "https://www.directives.doe.gov"
    },
    {
      "site": "standards",
      "dev_domain": "https://standards.dev.doxcelerate.com",
      "prod_domain": "https://www.standards.doe.gov"
    },
    {
      "site": "nnsa",
      "dev_domain": "https://directives.nnsa.dev.doxcelerate.com",
      "prod_domain": "https://directives.nnsa.doe.gov"
    }
  ],
  getSiteURL: function (requested_site_name) {
    const site = this.domains.find((s) => s.site === requested_site_name);
    return process.env.NODE_ENV === "development" ? site['dev_domain'] : site['prod_domain'];
  },
  updateURL: function (url) {
    return url
  }
}

export default function applyConfig(config) {

  SearchSchemaEnhancer(config);
  installAdvancedLink(config);

  config.blocks.blocksConfig.listing.variations.push(
    ListingTableVariation,
    ListingNewsVariation
  );

  config.views.contentTypesViews["DOE.Directives.directive"] = TypesView;
  config.views.contentTypesViews["DOE.Directives.delegation"] = TypesView;
  config.views.contentTypesViews["DOE.Directives.definition"] = DoeDefinition;

  config.settings = {
    ...config.settings,
    serverConfig: {
      ...config.settings.serverConfig,
      extractScripts: {
        ...config.settings.serverConfig.extractScripts,
        errorPages: true,
      },
    },
    isMultilingual: false,
    supportedLanguages: ['en'],
    defaultLanguage: 'en',
    apiExpanders: [
      ...config.settings.apiExpanders,
      {
        match: '',
        GET_CONTENT: ['breadcrumbs', 'actions', 'types', 'navroot'],
      },
      {
        match: '',
        GET_CONTENT: ['navigation'],
        querystring: (config) => ({
          'expand.navigation.depth': config.settings.navDepth,
        }),
      },
    ],
    appExtras: [
      ...config.settings.appExtras,
      {
        match: '',
        component: CookieConsent
      },
      {
        match: '',
        component: GoogleAnalytics
      },
    ],
    expressMiddleware: [
      setupServerMiddleware('path-replacer-middleware', '*', function (req, res, next) {
        const replacements = [
          {
            test: /\/@@images\/.+\.png$/,
            replacer: "/@@images/image"
          },
          {
            test: /\/at_download\/file$/,
            replacer: "/@@images/file"
          }
        ];
        replacements.forEach(({ test, replacer }) => {
          if (test.test(req.url)) {
            req.url = req.url.replace(test, replacer);
          }
        });

        next();
      })
      , ...config.settings?.expressMiddleware || []
    ],
    portals
  };



  return config;
}
